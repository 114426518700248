<template>
  <div id="app-layout">
    <div class="bg-sand">
      <div class="inner-container">
        <app-header />
      </div>
      <div class="inner-container mt-4">
        <notification-box
          v-if="partnerAccountLink"
          type="info"
        >
          <div class="text-label">Partner's account</div>
          <p>Click below for instructions on getting your partner's account.</p>
          <v-btn
            text
            color="orange"
            class="text-capitalize bg-grey-20"
            @click="partnerInstructions = true"
            >See Instructions</v-btn
          >
        </notification-box>
      </div>
      <div class="inner-container collapse mt-4">
        <app-nav />
      </div>
    </div>
    <div class="bg-sand-white">
      <div class="inner-container collapse pt-4">
        <div class="content-box">
          <transition
            mode="out-in"
            name="fade"
          >
            <router-view :key="$route.path" />
          </transition>
          <div class="divider opacity-60" />
          <div class="pa-4 bg-grey-10">
            <div class="text-center font-weight-bold">
              Australia's #1 online Wills & Estates platform.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pa-12">
      <img
        src="~@/v2/assets/images/footer.svg"
        id="app-illustration"
      />
    </div>
    <app-footer />
    <UserMenu :open.sync="userMenuOpen" />
    <dialog-small
      title="Thank you for choosing Willed!"
      :open.sync="postPurchaseInstructions"
    >
      <post-purchase-instructions v-if="postPurchaseInstructions" :products="purchasedProducts" />
    </dialog-small>
    <drawer
      title="Partners Account"
      :open.sync="partnerInstructions"
    >
      <div class="pa-4">
        <p>The URL below can be used to create an account for your partner.</p>
        <div class="text-label mb-2">Click to copy:</div>
        <v-text-field
          filled
          hide-details
          readonly
          append-icon="mdi-content-copy"
          :value="partnerAccountLink"
          class="copy-text mb-4"
          @click="
            $event.target.select()
            copyText()
          "
          @click:append="copyText"
          @focus="$event.target.select()"
        />
        <p>
          Once you have copied the link above you can send it to your partner
          for them to create their account, or use it to create an account for
          them.
        </p>
      </div>
    </drawer>
  </div>
</template>

<script>
import PostPurchaseInstructions from "../components/content/PostPurchaseInstructions"
import AppFooter from "../components/site/AppFooter"
import AppHeader from "../components/site/AppHeader"
import AppNav from "../components/site/AppNav"
import UserMenu from "../components/site/UserMenu"
import NotificationBox from "../components/ui/NotificationBox"
import DialogSmall from "../components/wrapper/DialogSmall"
import Drawer from "../components/wrapper/Drawer"

export default {
  name: "Default",
  components: {
    Drawer,
    DialogSmall,
    NotificationBox,
    UserMenu,
    AppFooter,
    AppHeader,
    AppNav,
    PostPurchaseInstructions,
  },
  data: () => ({
    domain: process.env.VUE_APP_DOMAIN,
    partnerInstructions: false,
    userMenuOpen: false,
    postPurchaseInstructions: false,
    purchasedProducts: [],
  }),
  created() {
    window.scrollTo(0, 0)

    // Post purchase
    // pt => payment, pc => promocode, pd => price, pp => prepaid, pn => product
    if (this.$route.query.pt) {
      // Show popup with next steps after purchasing will
      if (this.$route.query.pn !== "subscription") {
        this.postPurchaseInstructions = true
      }

      this.purchasedProducts = this.$route.query.pn.split("_")

      // mailchimp: tag as purchased (user update trigger)
      // email: send partner email (purchase function)
      // email: purchaseComplete (purchase function)

      let auth = this.$store.getters["account/auth_user"]
      let price = this.$route.query.pd || 0

      this.$router.replace(this.$route.path)

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "willPurchased",
        userId: auth.uid,
        userEmail: auth.email,
        purchasePrice: price,
        transactionId: auth.token,
        transactionTotal: price,
      })
      window.dataLayer.push({
        event: "purchase",
        order_value: price,
        order_id: auth.token,
        enhanced_conversion_data: {
          email: auth.email,
        },
      })
    }
  },
  methods: {
    copyText() {
      navigator.clipboard.writeText(this.partnerAccountLink)
      this.$store.commit("app/SET_MESSAGE", {
        type: null,
        text: "Link copied to clipboard",
      })
    },
  },
  computed: {
    partnerAccountLink() {
      let meta = this.$store.getters["account/user_meta_data"]
      if (meta?.partner?.token && !meta.partner.claimed) {
        return `${this.domain}/get-started?ptok=${meta.partner.token}`
      }

      return null
    },
  },
}
</script>

<style lang="scss"></style>
